import { createApp } from "vue";
import { createPinia } from "pinia";

import PrimeVue from "primevue/config";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { i18n } from "@/lang";
import * as primevueBR from "@/lang/prime-vue/pt-BR.json";

import { registerIcons } from "@/assets/font-awesome";

// PrimeVue components.
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import AutoComplete from "primevue/autocomplete";
import Avatar from "primevue/avatar";
import Badge from "primevue/badge";
import Button from "primevue/button";
import Card from "primevue/card";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import Chips from "primevue/chips";
import ConfirmDialog from "primevue/confirmdialog";
import DeferredContent from "primevue/deferredcontent";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import Galleria from "primevue/galleria";
import Image from "primevue/image";
import InlineMessage from "primevue/inlinemessage";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import Menu from "primevue/menu";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import Panel from "primevue/panel";
import Password from "primevue/password";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import RadioButton from "primevue/radiobutton";
import SelectButton from "primevue/selectbutton";
import Sidebar from "primevue/sidebar";
import Tag from "primevue/tag";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";

// PrimeVue services/plugins.
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

// PrimeVue directives.
import BadgeDirective from "primevue/badgedirective";
import Ripple from "primevue/ripple";
import StyleClass from "primevue/styleclass";
import Tooltip from "primevue/tooltip";

import { ClickOutside } from "@/directives/click-outside/index";

import PrivateLayout from "@/layouts/private-layout";
import Camera from "@/components/camera/v-camera.vue";
import { IMaskComponent as ImaskInput } from "vue-imask";

import App from "./app.vue";
import { router } from "./router";

// Related to vee-validate
import * as Yup from "yup";
import { ptForm } from "yup-locale-pt";

import "./styles/main.scss";

init();

async function init() {
  const app = createApp(App);

  app.use(createPinia());
  app.use(router);
  app.use(i18n);
  app.use(PrimeVue, {
    locale: {
      ...primevueBR,
    },
  });
  app.use(ConfirmationService);
  app.use(ToastService);

  // Directives
  app.directive("badge", BadgeDirective);
  app.directive("ripple", Ripple);
  app.directive("styleclass", StyleClass);
  app.directive("tooltip", Tooltip);
  app.directive("click-outside", ClickOutside);

  // Package Components
  app.component("font-awesome-icon", FontAwesomeIcon);

  // Local Components
  app.component("private-layout", PrivateLayout);
  app.component("v-camera", Camera);

  // PrimeVue Components
  app.component("PrimeAccordion", Accordion);
  app.component("PrimeAccordionTab", AccordionTab);
  app.component("PrimeAutoComplete", AutoComplete);
  app.component("PrimeAvatar", Avatar);
  app.component("PrimeBadge", Badge);
  app.component("PrimeButton", Button);
  app.component("PrimeCard", Card);
  app.component("PrimeCheckbox", Checkbox);
  app.component("PrimeChip", Chip);
  app.component("PrimeChips", Chips);
  app.component("PrimeConfirmDialog", ConfirmDialog);
  app.component("PrimeDeferredContent", DeferredContent);
  app.component("PrimeDialog", Dialog);
  app.component("PrimeDivider", Divider);
  app.component("PrimeDropdown", Dropdown);
  app.component("PrimeFileUpload", FileUpload);
  app.component("PrimeGallery", Galleria);
  app.component("PrimeImage", Image);
  app.component("PrimeInlineMessage", InlineMessage);
  app.component("PrimeInputMask", InputMask);
  app.component("PrimeInputNumber", InputNumber);
  app.component("PrimeInputText", InputText);
  app.component("PrimeMenu", Menu);
  app.component("PrimeMessage", Message);
  app.component("PrimeMultiSelect", MultiSelect);
  app.component("PrimePanel", Panel);
  app.component("PrimePassword", Password);
  app.component("PrimeProgressBar", ProgressBar);
  app.component("PrimeRadioButton", RadioButton);
  app.component("PrimeSelectButton", SelectButton);
  app.component("PrimeSidebar", Sidebar);
  app.component("PrimeTag", Tag);
  app.component("PrimeTextarea", Textarea);
  app.component("PrimeToast", Toast);
  app.component("PrimeProgressSpinner", ProgressSpinner);

  // Other components
  app.component("ImaskInput", ImaskInput);

  // Font Awesome icons are registered in a dedicated file
  registerIcons();

  Yup.setLocale(ptForm);

  app.mount("#app");
}
