<template>
  <div class="grid gap-y-5 grid-cols-1">
    <form @submit.prevent="submit">
      <div
        v-for="(question, key) in questionnaireQuestions"
        v-bind:key="key"
        class="flex flex-column gap-y-2 mb-4"
      >
        <label class="font-semibold" :for="question.type">{{
          question.title
        }}</label>
        <component
          v-if="question.type !== 'file-upload'"
          v-bind:is="'prime-' + question.type"
          :options="question.options ? question.options.data : null"
          :id="question.value"
          v-model="questionnaireResponses[question.value]"
          aria-labelledby="basic"
          option-label="label"
          option-value="value"
        ></component>
        <div v-else>
          <div>
            <prime-file-upload
              ref="fileUploadInputRef"
              mode="basic"
              accept=".jpg,.jpeg,.png,.bmp,.svg,.pdf"
              upload-label="Oi"
              class="!rounded-2xl"
              :maxFileSize="50000000"
              :chooseLabel="t('attach').toUpperCase()"
              :invalid-file-size-message="t('invalid_file_size_message')"
              :invalid-file-type-message="t('invalid_file_type_message')"
              @select="({ files }) => fileSelected(files, question.value)"
            />
          </div>
          <div class="grid gap-y-2 mt-2">
            <div
              v-for="(file, key) in questionnaireResponses[question.value]"
              v-bind:key="'file-' + key"
              class="flex justify-between"
            >
              <div class="flex gap-2">
                <font-awesome-icon icon="fal fa-image" class="w-6 h-6" />
                <p>{{ file.name }}</p>
              </div>
              <font-awesome-icon
                class="cursor-pointer"
                icon="far fa-xmark"
                @click="removeFile(key, question.value)"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <prime-button
          :label="
            loading ? capitalize(t('loading')) : capitalize(t('continue'))
          "
          type="submit"
          :disabled="loading"
          :loading="loading"
          class="w-full !rounded-full"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { capitalize, isEmpty, notifyError } from "@/utils";
import { onMounted, ref } from "vue";
import { useManagerApi } from "@/api";
import { useToast } from "primevue/usetoast";
import { i18n } from "@/lang";
import { useCheckInStore } from "@/stores";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const toast = useToast();

const checkInStore = useCheckInStore();

const uuid = route.params.uuid;

const questionnaireQuestions = ref(null);
const questionnaireResponses = ref({});

const loading = ref(false);

const fileUploadInputRef = ref(null);

onMounted(() => {
  checkInStore.changeStepTitle("health_questionnaire");
  fetchHealthQuestionnaire();
});

const fetchHealthQuestionnaire = () => {
  useManagerApi()
    .get("/fast-checkin/health-questionnaire")
    .then(({ data }) => {
      questionnaireQuestions.value = data.data;
    });
};

const submit = () => {
  const data = new FormData();
  loading.value = true;

  let i = 0;
  for (const [questionType, response] of Object.entries(
    questionnaireResponses.value
  )) {
    data.append(`responses[${i}][question_type]`, questionType);

    if (response instanceof Array) {
      response.forEach((responseItem, index) => {
        data.append(`responses[${i}][response][${index}]`, responseItem);
      });
    } else {
      data.append(`responses[${i}][response]`, response);
    }

    i++;
  }

  useManagerApi()
    .post(`/fast-checkin/health-questionnaire/${uuid}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(() => {
      return router.push({
        name: "check-in-steps.health-questionnaire-completed",
        params: { uuid },
      });
    })
    .catch((e) => {
      if (e?.response?.data) {
        const response = e.response;
        if (response.status === 422) {
          notifyError(
            toast,
            capitalize(i18n.global.t("please_fill_out_the_entire_form")),
            5000
          );
          return;
        }
      }

      notifyError(toast, capitalize(i18n.global.t("unexpected_error")), 5000);
    })
    .finally(() => {
      loading.value = false;
    });
};
const fileSelected = (files, field) => {
  if (isEmpty(questionnaireResponses.value[field])) {
    questionnaireResponses.value[field] = [];
  }

  questionnaireResponses.value[field].push(files[0]);

  fileUploadInputRef.value[0].clear();
};

const removeFile = (index, field) => {
  questionnaireResponses.value[field].splice(index, 1);
};
</script>

<style>
.p-selectbutton {
  box-shadow: none;
  display: flex;
}

.p-selectbutton > .p-button {
  flex: 1;
}
</style>
