import axios from "axios";
import { MANAGER_API_URL, API_URL } from "@/constants";
import { storeToRefs } from "pinia";
import { useAppStore } from "@/stores";

export const useApi = (prefix = null) => {
  const appStore = useAppStore();
  const { customApp, clientIdentifier } = storeToRefs(appStore);

  const instance = axios.create({
    baseURL: prefix ? `${API_URL}/${prefix}` : API_URL,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Cache-Control": "no-store",
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-client-identifier": customApp.value ? clientIdentifier.value : null,
    },
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error?.response?.status === 419 && !originalRequest._retry) {
        originalRequest._retry = true;

        await csrfToken();

        return instance.request(error.config);
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export const useManagerApi = (prefix = "api") => {
  const appStore = useAppStore();
  const { customApp, clientIdentifier } = storeToRefs(appStore);

  const instance = axios.create({
    baseURL: `${MANAGER_API_URL}/${prefix}`,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Cache-Control": "no-store",
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-client-identifier": customApp.value ? clientIdentifier.value : null,
    },
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error?.response?.status === 419 && !originalRequest._retry) {
        originalRequest._retry = true;

        await csrfTokenManager();

        return instance.request(originalRequest);
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export const csrfTokenManager = () => {
  return new Promise((resolve, reject) => {
    useManagerApi("sanctum")
      .get("csrf-cookie")
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};

export const csrfToken = () => {
  return new Promise((resolve, reject) => {
    useApi("sanctum")
      .get("csrf-cookie")
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};
