import { defineStore } from "pinia";

export const useSchedulesCartStore = defineStore({
  id: "SchedulesCartStore",
  state: () => ({
    // TODO: Quando finalizar a refatoração, remover esse atributo
    scheduleTimes: [],
    availableSlots: [],
  }),
  actions: {
    addAvailableSlot(schedule) {
      if (!this.availableSlots) {
        this.availableSlots = [];
      }

      if (
        !this.availableSlots.some(
          (availableSlot) =>
            availableSlot.schedule_config_external_id ===
            schedule.schedule_config_external_id
        )
      ) {
        this.availableSlots.push(schedule);
      }
    },
    removeAvailableSlot(availableSlot) {
      this.availableSlots = this.availableSlots.filter(function (s) {
        return s.id !== availableSlot.id;
      });
    },
    clearAvailableSlots() {
      this.availableSlots = [];
    },
  },
  getters: {
    amountItemsCart(state) {
      // TODO: ajustar aqui quando tiver os preços
      return state.availableSlots.reduce(
        (total, scheduleTime) => total + scheduleTime.value,
        0
      );
    },
    quantityItemsCart(state) {
      return state.availableSlots.length;
    },
  },
});
