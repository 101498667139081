export default [
	'about',
	'above',
	'across',
	'after',
	'against',
	'along',
	'among',
	'around',
	'at',
	'because of',
	'before',
	'behind',
	'below',
	'beneath',
	'beside',
	'besides',
	'between',
	'beyond',
	'but',
	'by',
	'concerning',
	'despite',
	'down',
	'during',
	'except',
	'excepting',
	'for',
	'from',
	'in',
	'in front of',
	'inside',
	'in spite of',
	'instead of',
	'into',
	'like',
	'near',
	'of',
	'off',
	'on',
	'onto',
	'out',
	'outside',
	'over',
	'past',
	'regarding',
	'since',
	'through',
	'throughout',
	'to',
	'toward',
	'under',
	'underneath',
	'until',
	'up',
	'upon',
	'up to',
	'with',
	'within',
	'without',
	'with regard to',
	'with respect to',
  'a',
  'ante',
  'até',
  'após',
  'com',
  'contra',
  'de',
  'do',
  'desde',
  'em',
  'entre',
  'para',
  'por',
  'perante',
  'sem',
  'sob',
  'sobre',
  'trás',
];
