<template>
  <div
    aria-live="assertive"
    class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="needRefresh"
          class="relative transform overflow-hidden rounded-lg bg-white border text-left shadow-md transition-all w-full sm:w-72 pointer-events-auto"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="w-0 flex-1 text-right space-y-1">
                <p class="text-md font-medium text-gray-900">
                  {{ capitalize(t("new_app_version_available")) }}
                </p>
                <div class="flex justify-end">
                  <prime-button
                    :label="capitalize(t('update_now'))"
                    size="small"
                    @click="updateServiceWorker"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { useRegisterSW } from "virtual:pwa-register/vue";
import { useI18n } from "vue-i18n";
import { capitalize } from "@/utils/format-title";

const { needRefresh, updateServiceWorker } = useRegisterSW();
const { t } = useI18n();
</script>
