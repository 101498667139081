import { ToastSeverity } from "primevue/api";
import { i18n } from "@/lang";
import { capitalize } from "@/utils";

export function notifySuccess(toast, message, duration = 3000) {
  toast.add({
    ...makeNotification(),
    severity: ToastSeverity.SUCCESS,
    summary: capitalize(i18n.global.t("success")),
    detail: message,
    life: duration,
  });
}

export function notifyInfo(toast, message, duration = 3000) {
  toast.add({
    ...makeNotification(),
    detail: message,
    life: duration,
  });
}

export function notifyWarn(toast, message, duration = 3000) {
  toast.add({
    ...makeNotification(),
    severity: ToastSeverity.WARN,
    summary: capitalize(i18n.global.t("warn")),
    detail: message,
    life: duration,
  });
}

export function notifyError(toast, message, duration = 3000) {
  toast.add({
    ...makeNotification(),
    severity: ToastSeverity.ERROR,
    summary: capitalize(i18n.global.t("error")),
    detail: message,
    life: duration,
  });
}

function makeNotification() {
  return {
    severity: ToastSeverity.INFO,
    summary: capitalize(i18n.global.t("info"))
  };
}
