<template>
  <prime-progress-bar
    v-if="percentageTimeOfSession"
    :value="percentageTimeOfSession"
    :pt="{
      label: { style: { display: 'none' } },
      root: {
        style: {
          height: '6px',
          position: 'fixed',
          top: 0,
          width: '100%',
          'z-index': 3,
        },
      },
    }"
  />

  <prime-dialog
    class="w-4/5 sm:w-2/3 md:w-2/4 lg:w-1/3"
    :modal="true"
    header=" "
    v-model:visible="showExpiredMessage"
    @show="onShowExpiredMessage"
    @hide="onHideExpiredMessage"
  >
    <div class="text-center">
      <font-awesome-icon
        icon="fa-duotone fa-triangle-exclamation"
        class="h-12 w-12 text-yellow-500"
      />

      <div
        class="text-xl text-neutral-800 leading-9 font-semibold capitalize py-1"
      >
        {{ capitalize(t("attention")) }}
      </div>
    </div>

    <div class="py-2 text-600">
      {{ capitalize(t("expired_schedule_session_message")) }}
    </div>

    <prime-progress-bar
      class="mt-4 mb-8"
      :value="percentageTimeToShowMessage"
      :pt="{
        label: { style: { display: 'none' } },
        root: { style: { height: '6px' } },
      }"
    />
  </prime-dialog>
</template>

<script setup>
import { capitalize, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useScheduleSessionStore } from "@/stores";
import { useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();
const scheduleSessionStore = useScheduleSessionStore();

const showExpiredMessage = ref(false);
const percentageTimeOfSession = ref(0);
const percentageTimeToShowMessage = ref(100);
let showMessageTimeInterval = null;

onMounted(() => {
  setInterval(() => {
    percentageTimeOfSession.value = scheduleSessionStore.percentageTimeLeft();
    showExpiredMessage.value = scheduleSessionStore.checkIfExpired();
  }, 2000);
});

function onShowExpiredMessage() {
  const showTime = 15;
  let showTimeLeft = showTime;

  showMessageTimeInterval = setInterval(() => {
    showTimeLeft -= 0.25;
    percentageTimeToShowMessage.value = (showTimeLeft * 100) / showTime;
    if (0 === showTimeLeft) {
      showExpiredMessage.value = false;
    }
  }, 250);
}

function onHideExpiredMessage() {
  scheduleSessionStore.resetSession();
  percentageTimeToShowMessage.value = 100;
  clearInterval(showMessageTimeInterval);
  router.push({ name: "user.dashboard" });
}
</script>
