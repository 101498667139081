<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="grow flex flex-col justify-center items-center">
        <div class="text-center mb-4">
          <font-awesome-icon
            icon="fa-light fa-circle-check"
            class="h-20 w-20 text-green-700"
          />
          <div class="text-3xl text-neutral-800 leading-7 font-semibold py-1">
            {{ t("waiting_for_telemedicine_title") }}
          </div>
        </div>
        <div class="text-lg text-center">
          {{ capitalize(t("waiting_for_telemedicine_description")) }}
        </div>
      </div>

      <div class="text-center w-full">
        <prime-button
          class="w-full max-w-96 !rounded-2xl"
          :label="capitalize(t('go_to_home'))"
          @click="router.push({ name: 'user.dashboard' })"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { capitalize } from "@/utils";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { useCheckInStore } from "@/stores";

const { t } = useI18n();
const router = useRouter();

const checkInStore = useCheckInStore();

onMounted(() => {
  checkInStore.changeStepTitle();
});
</script>
