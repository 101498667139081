import { defineStore } from "pinia";
import { useApi } from "@/api";

const CONSULTATION = "consulta";
const EXAM = "exame";
const SESSION = "sessão";

export const usePatientScheduleServicesStore = defineStore({
  id: "patientScheduleServicesStore",
  state: () => ({
    patientScheduleServices: [],
    loading: false,
    error: null,
    hydrated: false,
  }),
  actions: {
    async hydrate(prefix = "") {
      this.loading = true;

      try {
        const { data } = await useApi("front").get(
          `${prefix}/patient-schedule-services`
        );

        this.patientScheduleServices = data.data;

        this.hydrated = true;
      } catch (e) {
        this.error = e;
        throw e;
      } finally {
        this.loading = false;
      }
    },
    async dehydrate() {
      this.$reset();
    },
  },
  getters: {
    exam(state) {
      return state.patientScheduleServices.find(
        (service) => EXAM === service.description.toLowerCase()
      );
    },
    consultation(state) {
      return state.patientScheduleServices.find(
        (service) => CONSULTATION === service.description.toLowerCase()
      );
    },
    session(state) {
      return state.patientScheduleServices.find(
        (service) => SESSION === service.description.toLowerCase()
      );
    },
    serviceOptionsToSchedulesSearch(state) {
      return state.patientScheduleServices.filter((service) =>
        [CONSULTATION, EXAM].includes(service.description.toLowerCase())
      );
    },
  },
});
