import { defineStore } from "pinia";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import axios from "axios";
import { useApi } from "@/api.js";
import { formatTitle } from "@/utils";
import { notEmpty } from "@/utils";
import { useCartStore, useBiometricsStore } from "@/stores";

export const usePatientStore = defineStore({
  id: "patientStore",
  state: () => ({
    patient: null,
    surveyUrl: null,
    loading: false,
    error: null,
    hydrated: false,
  }),
  actions: {
    async hydrate() {
      const cartStore = useCartStore();
      const biometricsStore = useBiometricsStore();
      this.loading = true;

      try {
        const { data } = await useApi("front").get("user/me");

        const patient = data.data;

        this.patient = {
          ...patient,
          name: notEmpty(patient.name)
            ? formatTitle(patient.name)
            : patient.name,
          birthday_formatted:
            patient.birthday !== null && patient.birthday !== undefined
              ? format(new Date(patient.birthday), "dd/MM/yyyy", {
                  locale: ptBR,
                })
              : null,
        };

        if (patient?.emergency_profile?.avatar) {
          try {
            await axios.head(patient?.emergency_profile?.avatar);
          } catch (error) {
            this.patient.emergency_profile.avatar = null;
          }
        }

        this.setPatientAddresses(patient.addresses);

        if (patient.current_cart_id !== null) {
          await cartStore.getCart(patient.current_cart_id);
        }

        await biometricsStore.checkRegisteredFace();

        this.hydrated = true;
      } catch (e) {
        this.error = e;
        throw e;
      } finally {
        this.loading = false;
      }
    },
    setPatientAddresses(addresses) {
      const formatPostalCode = (postalCode) => {
        if (notEmpty(postalCode)) {
          return postalCode
            .replace(/\D/g, "")
            .replace(/(\d{5})(\d)/, "$1-$2")
            .replace(/(-\d{3})\d+?$/, "$1");
        }

        return postalCode;
      };

      this.patient.addresses = addresses.map((address) => ({
        ...address,
        address: notEmpty(address.address)
          ? formatTitle(address.address)
          : address.address,
        city: notEmpty(address.city) ? formatTitle(address.city) : address.city,
        address_complement: notEmpty(address.address_complement)
          ? formatTitle(address.address_complement)
          : address.address_complement,
        neighborhood: notEmpty(address.neighborhood)
          ? formatTitle(address.neighborhood)
          : address.neighborhood,
        postal_code: notEmpty(address.postal_code)
          ? formatPostalCode(address.postal_code)
          : address.postal_code,
      }));
    },
    async dehydrate() {
      const biometricsStore = useBiometricsStore();
      await biometricsStore.dehydrate();

      this.$reset();
    },
    setSurveyUrl(url) {
      this.surveyUrl = url;
    },
    async uploadAvatar(file) {
      const formData = new FormData();
      formData.append("avatar", file);
      const uuid = this.patient.emergency_profile.uuid;
      const { data } = await useApi("front").post(
        `patient-emergency-profiles/${uuid}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      this.patient.emergency_profile = data.data;
    },
    async uploadBirthmarkScarTattooPicture(file) {
      const formData = new FormData();
      formData.append("picture", file);
      const uuid = this.patient.emergency_profile.uuid;
      const { data } = await useApi("front").post(
        `patient-emergency-profiles/${uuid}/birthmark-scar-tattoo-pictures`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.patient.emergency_profile = data.data;
    },
    async updateBirthmarkScarTattooDescription(description) {
      const uuid = this.patient.emergency_profile.uuid;
      const { data } = await useApi("front").post(
        `patient-emergency-profiles/${uuid}/birthmark-scar-tattoo-description`,
        {
          birthmark_scar_tattoo_description: description,
        }
      );
      this.patient.emergency_profile = data.data;
    },
    async deleteBirthmarkScarTattooPicture(pictureUuid) {
      const uuid = this.patient.emergency_profile.uuid;
      const { data } = await useApi("front").delete(
        `patient-emergency-profiles/${uuid}/birthmark-scar-tattoo-pictures/${pictureUuid}`
      );
      this.patient.emergency_profile = data.data;
    },
    async storeContacts(contact) {
      let uuid = this.patient.emergency_profile.uuid;
      return await useApi("front").post(
        `patient-emergency-profiles/${uuid}/emergency-contacts`,
        { ...contact }
      );
    },
    async updateContact(contact) {
      let uuid = this.patient.emergency_profile.uuid;
      const { data } = await useApi("front").put(
        `patient-emergency-profiles/${uuid}/emergency-contacts/${contact.id}`,
        { ...contact }
      );
      this.patient.emergency_profile = data.data;
    },
    async deleteContact(contact) {
      let uuid = this.patient.emergency_profile.uuid;
      const { data } = await useApi("front").delete(
        `patient-emergency-profiles/${uuid}/emergency-contacts/${contact.id}`,
        { ...contact }
      );
      this.patient.emergency_profile = data.data;
    },

    async handlesSuggestedHealthProblems(healthProblemsIds) {
      const uuid = this.patient.emergency_profile?.uuid;
      if (!uuid) return;
      const { data } = await useApi("front").post(
        `patient-emergency-profiles/${uuid}/health-problems/suggested`,
        {
          health_problems_ids: healthProblemsIds,
        }
      );
      this.patient.emergency_profile = data.data;
    },
    async handlesOtherHealthProblems(otherHealthProblem) {
      const uuid = this.patient.emergency_profile?.uuid;
      if (!uuid) return;
      const { data } = await useApi("front").post(
        `patient-emergency-profiles/${uuid}/health-problems/other`,
        {
          health_problem: otherHealthProblem,
        }
      );
      this.patient.emergency_profile = data.data;
    },
    async deleteHealthProblems(healthProblemId) {
      const uuid = this.patient.emergency_profile?.uuid;
      if (!uuid) return;
      const { data } = await useApi("front").delete(
        `patient-emergency-profiles/${uuid}/health-problems/${healthProblemId}`
      );
      this.patient.emergency_profile = data.data;
    },

    updateAllergies(allergies) {
      const uuid = this.patient.emergency_profile?.uuid;
      if (!uuid) return;
      useApi("front")
        .post(`patient-emergency-profiles/${uuid}/allergies`, allergies)
        .then(() => (this.patient.emergency_profile.allergies = allergies));
    },

    async updateDrugsFromSuggested(selectedDrugs) {
      const uuid = this.patient.emergency_profile?.uuid;
      if (!uuid) return;
      const { data } = await useApi("front").post(
        `patient-emergency-profiles/${uuid}/drugs/suggested`,
        {
          drug_ids: selectedDrugs,
        }
      );
      this.patient.emergency_profile = data.data;
    },
    async updateDrugsFromOther(otherDrug) {
      const uuid = this.patient.emergency_profile?.uuid;
      if (!uuid) return;
      const { data } = await useApi("front").post(
        `patient-emergency-profiles/${uuid}/drugs/other`,
        {
          drug: otherDrug,
        }
      );
      this.patient.emergency_profile = data.data;
    },
    async deleteDrugs(drugId) {
      const uuid = this.patient.emergency_profile?.uuid;
      if (!uuid) return;
      const { data } = await useApi("front").delete(
        `patient-emergency-profiles/${uuid}/drugs/${drugId}`
      );
      this.patient.emergency_profile = data.data;
    },
    async uploadDrugPicture(file) {
      const formData = new FormData();
      formData.append("picture", file);
      const uuid = this.patient.emergency_profile.uuid;
      const { data } = await useApi("front").post(
        `patient-emergency-profiles/${uuid}/drugs/pictures`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.patient.emergency_profile = data.data;
    },
    async updateEmergencyProfile(updates) {
      const to_update = {
        blood_type: this.patient.emergency_profile.blood_type,
        height: this.patient.emergency_profile.height,
        weight: this.patient.emergency_profile.weight,
      };

      const { data } = await useApi("front").post(
        `patient-emergency-profiles/${this.patient.emergency_profile.uuid}`,
        {
          ...to_update,
          ...updates,
        }
      );

      this.patient.emergency_profile = data.data;
    },

    async storeSuggestedHealthPlan(healthPlanId, healthPlanNumber) {
      const uuid = this.patient.emergency_profile?.uuid;
      if (!uuid) return;
      const { data } = await useApi("front").post(
        `patient-emergency-profiles/${uuid}/health-plans/suggested`,
        {
          health_plan_id: healthPlanId,
          card_number: healthPlanNumber,
        }
      );
      return data.data;
    },
    async storeOtherHealthPlan(healthPlan, healthPlanNumber) {
      const uuid = this.patient.emergency_profile?.uuid;
      if (!uuid) return;
      const { data } = await useApi("front").post(
        `patient-emergency-profiles/${uuid}/health-plans/other`,
        {
          health_plan: healthPlan,
          card_number: healthPlanNumber,
        }
      );
      return data.data;
    },
    deleteHealthPlans(healthPlanId) {
      const uuid = this.patient.emergency_profile?.uuid;
      if (!uuid) return;
      useApi("front")
        .delete(
          `patient-emergency-profiles/${uuid}/health-plans/${healthPlanId}`
        )
        .then(({ data }) => (this.patient.emergency_profile = data.data));
    },

    async handlesSuggestedAllergies(allergiesIds) {
      const uuid = this.patient.emergency_profile?.uuid;
      if (!uuid) return;
      const { data } = await useApi("front").post(
        `patient-emergency-profiles/${uuid}/allergies/suggested`,
        {
          allergies_ids: allergiesIds,
        }
      );
      this.patient.emergency_profile = data.data;
    },
    async handleOtherAllergies(otherAllergy) {
      const uuid = this.patient.emergency_profile?.uuid;
      if (!uuid) return;
      const { data } = await useApi("front").post(
        `patient-emergency-profiles/${uuid}/allergies/other`,
        {
          allergy: otherAllergy,
        }
      );
      this.patient.emergency_profile = data.data;
    },
    async deleteAllergy(allergyId) {
      const uuid = this.patient.emergency_profile?.uuid;
      if (!uuid) return;
      const { data } = await useApi("front").delete(
        `patient-emergency-profiles/${uuid}/allergies/${allergyId}`
      );
      this.patient.emergency_profile = data.data;
    },
  },
  getters: {
    avatar(state) {
      return state?.patient?.emergency_profile?.avatar;
    },
    emergencyProfile(state) {
      return state.patient?.emergency_profile;
    },
    healthProblems(state) {
      return state.patient?.emergency_profile?.health_problems || [];
    },
    allergies(state) {
      return state.patient?.emergency_profile?.allergies || [];
    },
    drugs(state) {
      return state.patient?.emergency_profile?.drugs || [];
    },
    drugsWithoutPicture(state) {
      return state.patient?.emergency_profile?.drugs.filter(
        (d) => d.drug_photo_url === null
      );
    },
    drugsPictures(state) {
      return state.patient?.emergency_profile?.drugs.filter(
        (d) => d.drug_id === null && d.drug_photo_url !== null
      );
    },
    birthmarkScarTattooPhotos(state) {
      return (
        state.patient?.emergency_profile?.birthmark_scar_tattoo?.photos || []
      );
    },
    birthmarkScarTattooDescription(state) {
      return state.patient?.emergency_profile?.birthmark_scar_tattoo
        ?.description;
    },
    emergencyContacts(state) {
      return state.patient?.emergency_profile?.emergency_contacts || [];
    },
    healthPlans(state) {
      return state.patient?.emergency_profile?.health_plans || [];
    },
    availableModules(state) {
      const clients = state.patient?.unities || [];
      const clientModules = clients.reduce(
        (ac, client) => ac.concat(client?.modules || []),
        []
      );
      return [...new Set(clientModules)];
    },
    hasCurrentCart(state) {
      return (
        state.patient?.current_cart_id !== undefined &&
        state.patient?.current_cart_id !== null
      );
    },
    patientAge(state) {
      if (!state.patient.birthday) {
        return null;
      }

      const today = new Date();
      const birthDate = new Date(state.patient.birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const month = today.getMonth() - birthDate.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    },
  },
});
