import { defineStore } from "pinia";
import { useApi } from "@/api";

const FINALIZED_STEP = "finalized";
const TIPS_STEP = "tips";
const CAPTURE_STEP = "capture";
const CONFIRM_STEP = "confirm";
const DENIED_STEP = "denied";

const MODE_FRONT = "user";
const MODE_BACK = "environment";

export default {
  FINALIZED_STEP,
  TIPS_STEP,
  CAPTURE_STEP,
  CONFIRM_STEP,
  DENIED_STEP,
};

export const useBiometricsStore = defineStore({
  id: "biometricsStore",
  state: () => ({
    step: FINALIZED_STEP,
    facingMode: MODE_FRONT,
    currentSnapshot: String,
    hasBiometrics: false,
    updatedBiometrics: false,
    forceToSee: true,
    canAccessCamera: false,
    loading: false,
    error: null,
  }),
  actions: {
    setStep(payload) {
      this.step = payload;
    },
    setCurrentSnapshot(payload) {
      this.currentSnapshot = payload;
    },
    checkStep(value) {
      return this.step === value;
    },
    switchMode() {
      this.facingMode = this.facingMode === MODE_FRONT ? MODE_BACK : MODE_FRONT;
    },
    repeatSnapshot() {
      this.step = CAPTURE_STEP;
      this.currentSnapshot = "";
      this.error = null;
    },
    async checkRegisteredFace() {
      try {
        const { data } = await useApi("front").get(
          "face-recognition/check-registered-face"
        );
        this.hasBiometrics = data.registered;
      } catch (e) {
        this.error = e;
      }
    },
    async sendSnapshot() {
      this.loading = true;

      try {
        const formData = new FormData();
        const face = await fetch(this.currentSnapshot).then((r) => r.blob());
        formData.append("face", face);

        await useApi("front").post("face-recognition/register-face", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        this.hasBiometrics = true;
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    async dehydrate() {
      this.$reset();
    },
  },
});
