<template>
  <div class="h-full surface-ground">
    <div class="border-b border-gray-200 bg-white">
      <div
        class="mx-auto max-w-7xl px-4 surface-section flex justify-content-between relative lg:static"
        style="min-height: 70px"
      >
        <div class="align-self-center flex-1 lg:flex-initial">
          <img
            :src="`${logo}`"
            alt="logo"
            class="w-28 mr-0 lg:mr-6 align-self-center"
          />
        </div>

        <div v-if="!customApp" class="align-self-center pr-3 lg:hidden">
          <a
            class="flex px-2 py-1 bg-red-600 text-xs items-center text-white rounded-full hover:bg-red-500"
            :aria-label="t('call')"
            href="tel:192"
          >
            <font-awesome-icon
              icon="fa-regular fa-circle-phone"
              class="h-6 w-6"
            />
            <span class="flex flex-col uppercase mx-2">
              <span>{{ t("call") }}</span>
              <b>{{ t("samu") }}</b>
            </span>
          </a>
        </div>

        <div
          class="cursor-pointer flex space-x-4 lg:hidden align-self-center p-ripple text-700 pr-3"
          v-styleclass="{
            selector: '@next',
            enterClass: 'hidden',
            leaveToClass: 'hidden',
            hideOnOutsideClick: true,
          }"
        >
          <a v-ripple class="p-ripple">
            <i class="pi pi-bars text-4xl"></i>
          </a>
        </div>
        <div
          class="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none surface-overlay"
        >
          <ul
            class="list-none p-0 m-0 flex select-none flex-column lg:flex-row gap-2"
          >
            <li v-for="item in navigationMenu" :key="item.name" class="flex">
              <router-link
                :to="item.to"
                active-class="surface-ground text-900"
                exact-active-class="surface-ground text-900"
                v-ripple
                class="border-round-top flex align-items-center px-6 py-3 lg:px-3 lg:py-3 text-900 font-medium cursor-pointer transition-colors transition-duration-150 p-ripple no-underline"
              >
                <span>{{ item.name }}</span>
              </router-link>
            </li>
          </ul>
          <ul
            class="list-none p-0 lg:pr-6 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none"
          >
            <li
              v-if="!customApp"
              class="hidden border-top-1 surface-border lg:border-top-none lg:block"
            >
              <a
                class="flex px-2 py-1 bg-red-600 text-xs items-center text-white rounded-full hover:bg-red-500"
                :aria-label="t('call')"
                href="tel:192"
              >
                <font-awesome-icon
                  icon="fa-regular fa-circle-phone"
                  class="h-6 w-6"
                />
                <span class="flex flex-col uppercase mx-2">
                  <span>{{ t("call") }}</span>
                  <b>{{ t("samu") }}</b>
                </span>
              </a>
            </li>
            <li
              v-if="authenticated"
              class="border-top-1 surface-border lg:border-top-none"
            >
              <prime-button
                type="button"
                class="p-button-text"
                aria-haspopup="true"
                aria-controls="overlay_menu"
                @click="toggleUserNavigationMenu"
              >
                <prime-image
                  v-if="avatar"
                  :src="avatar"
                  imageClass="object-cover rounded-full h-10 w-10"
                />
                <font-awesome-icon
                  v-else
                  class="w-10 h-10 object-cover text-black-alpha-80"
                  icon="fad fa-circle-user"
                />
                <div class="block lg:hidden ml-2">
                  <div class="text-900 font-medium">{{ patient.name }}</div>
                </div>
              </prime-button>
              <prime-menu
                ref="userNavigationMenu"
                id="overlay_menu"
                :model="userNavigation"
                :popup="true"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="py-5 h-[calc(100%-85px)]">
      <div class="mx-auto sm:max-w-xl px-4">
        <slot name="header-actions" />
      </div>
      <!-- header -->
      <header class="mx-auto sm:max-w-xl px-4">
        <slot name="section-header">
          <div
            v-if="sectionHeader"
            class="grid grid-cols-5 align-items-center justify-content-between"
          >
            <h1
              class="col-span-3 text-3xl font-bold leading-tight tracking-tight text-gray-900"
            >
              {{ sectionHeader }}
            </h1>
            <div v-if="$slots.actions" class="col-span-2 text-end">
              <slot name="actions" />
            </div>
          </div>
        </slot>
      </header>
      <!-- content -->
      <main class="mx-auto sm:max-w-xl px-4 py-6 h-full">
        <slot />
      </main>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { i18n } from "@/lang";
import { useAppStore, usePatientStore } from "@/stores";
import { capitalize } from "@/utils/format-title";

defineProps({
  sectionHeader: {
    type: String,
    default: null,
  },
});

const { t } = useI18n();
const router = useRouter();

const patientStore = usePatientStore();
const { patient, avatar } = storeToRefs(patientStore);

const appStore = useAppStore();
const { logo, customApp, authenticated } = storeToRefs(appStore);

const userNavigationMenu = ref();
const userNavigation = ref([
  {
    items: [
      {
        label: capitalize(i18n.global.t("my_account")),
        command: () => router.push({ name: "user.profile.index" }),
      },
      {
        label: capitalize(i18n.global.t("patient_card")),
        command: () => router.push({ name: "patient-card" }),
      },
      {
        label: capitalize(i18n.global.t("facial_biometrics")),
        command: () => router.push({ name: "user.facial-biometrics" }),
      },
      {
        label: capitalize(i18n.global.t("logout")),
        command: () => router.push({ name: "logout" }),
      },
    ],
  },
]);
const toggleUserNavigationMenu = (event) => {
  userNavigationMenu.value.toggle(event);
};

const navigationMenu = ref(null);
const navigationLogged = [
  {
    name: capitalize(i18n.global.t("user_dashboard")),
    to: { name: "user.dashboard" },
    custom_app: true,
  },
  {
    name: capitalize(i18n.global.t("my_medical_appointment")),
    to: { name: "user.schedules.consultations.index" },
    custom_app: false,
  },
  {
    name: capitalize(i18n.global.t("exams_results")),
    to: { name: "user.schedules.exams.index" },
    custom_app: false,
  },
];
const navigationNotLogged = [
  {
    name: capitalize(i18n.global.t("login")),
    to: { name: "login" },
  },
];

onMounted(() => {
  const navigationLoggedFiltered = navigationLogged.filter((item) => {
    if (customApp.value && !item.custom_app) {
      return false;
    }

    return true;
  });

  navigationMenu.value = authenticated.value
    ? navigationLoggedFiltered
    : navigationNotLogged;
});
</script>
