export const HEALTH_QUESTIONNAIRE = "health_questionnaire";
export const PATIENT_COMPANIONS = "patient_companions";
export const DOCUMENTS_DIGITAL_SIGNATURE = "documents_digital_signature";
export const CAPTURE_FACE_FOR_DIGITAL_SIGNATURE =
  "capture_face_for_digital_signature";
export const SIGN_DOCUMENTS = "sign_documents";
export const SHOW_SIGNED_DOCUMENTS = "show_signed_documents";
export const LEGAL_GUARDIAN_CREATE = "legal_guardian_create";
export const CHECK_IN_TERMS = "check_in_terms";
export const WAITING_FOR_TELEMEDICINE = "waiting_for_telemedicine";
export const TELEMEDICINE_GUIDELINES = "telemedicine_guidelines";
