export default [
	'and',
	'that',
	'but',
	'or',
	'as',
	'if',
	'when',
	'than',
	'because',
	'while',
	'where',
	'after',
	'so',
	'though',
	'since',
	'until',
	'whether',
	'before',
	'although',
	'nor',
	'like',
	'once',
	'unless',
	'now',
	'except',
];
