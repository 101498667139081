<template>
  <div class="flex flex-col h-full px-8 justify-around">
    <div class="flex flex-col items-center space-y-4">
      <div class="text-center">
        <font-awesome-icon
          class="mb-12 text-gray-500"
          icon="fa-light fa-laptop-medical"
          size="10x"
        />
      </div>

      <h1
        class="pb-4 col-span-3 text-4xl font-bold leading-tight tracking-tight text-gray-900 text-center"
      >
        {{
          capitalize(
            t("telemedicine_start_view.guidelines_for_your_teleconsultation")
          )
        }}
      </h1>

      <ul class="space-y-2 text-gray-600 font-semibold text-lg">
        <li class="flex items-start space-x-2">
          <div class="w-6 text-center">
            <font-awesome-icon
              class="text-blue-500"
              icon="fa-solid fa-lightbulb-on"
            />
          </div>
          <div>
            <span>
              {{
                capitalize(
                  t(
                    "telemedicine_start_view.be_in_a_bright_and_quiet_environment"
                  )
                )
              }}
            </span>
          </div>
        </li>
        <li class="flex items-start space-x-2">
          <div class="w-6 text-center">
            <font-awesome-icon
              class="text-blue-500"
              icon="fa-solid fa-camera"
            />
          </div>
          <div>
            <span>
              {{
                capitalize(
                  t(
                    "telemedicine_start_view.remember_to_turn_on_your_camera_and_microphone"
                  )
                )
              }}
            </span>
          </div>
        </li>
        <li class="flex items-start space-x-2">
          <div class="w-6 text-center">
            <font-awesome-icon class="text-blue-500" icon="fa-solid fa-shirt" />
          </div>
          <div>
            <span>
              {{
                capitalize(
                  t(
                    "telemedicine_start_view.wear_appropriate_attire_video_consultation_seriousness"
                  )
                )
              }}
            </span>
          </div>
        </li>
      </ul>
    </div>

    <div class="w-full text-center">
      <prime-button
        id="btn-start"
        :label="capitalize(t('telemedicine_start_view.start_teleconsultation'))"
        type="button"
        @click="startTeleconsultation"
        size="large"
        class="!rounded-2xl sm:w-1/2 w-full"
      />
    </div>
  </div>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { capitalize } from "@/utils/format-title";
import { useRouter } from "vue-router";

const props = defineProps({
  uuid: {
    required: true,
    type: String,
  },
});

const { t } = useI18n();
const router = useRouter();

const startTeleconsultation = () => {
  router.push({
    name: "telemedicine-room",
    params: {
      uuid: props.uuid,
    },
  });
};
</script>
