<template>
  <div class="flex flex-col w-full lg:w-1/2 space-y-6">
    <p>
      {{ capitalize(t("sign_the_document_description")) }}
    </p>
    <div class="bg-gray-200 rounded-lg min-h-full pb-6">
      <div
        class="bg-gray-300 px-6 py-4 top-bar flex items-center rounded-t-lg justify-center"
      >
        <span class="page-info pr-6 border-r border-r-gray-600">
          {{ capitalize(t("page")) }}
          {{ pageNum }}/{{ pageCount }}
        </span>
        <div class="flex items-center rounded-t-lg ml-6 gap-2">
          <prime-button
            id="remove-zoom"
            class="text-gray-700 disabled:text-gray-500 disabled:cursor-not-allowed"
            :disabled="buttonRemoveZoomDisabled"
            :link="true"
            @click="zoomOut"
          >
            <font-awesome-icon
              :icon="['far', 'magnifying-glass-minus']"
              class="text-lg bg-none"
            ></font-awesome-icon>
          </prime-button>
          <prime-button
            id="add-zoom"
            class="text-gray-700 disabled:text-gray-500 disabled:cursor-not-allowed"
            :disabled="buttonAddZoomDisabled"
            :link="true"
            @click="zoomIn"
          >
            <font-awesome-icon
              :icon="['far', 'magnifying-glass-plus']"
              class="text-lg bg-none"
            ></font-awesome-icon>
          </prime-button>
        </div>
      </div>
      <div v-show="!loadingFile" class="max-h-[600px] overflow-y-auto pt-6">
        <div class="px-4 mb-6">
          <div class="max-w-full overflow-x-auto">
            <canvas class="mx-auto" id="pdf-viewer"></canvas>
          </div>
        </div>
      </div>
      <div v-show="loadingFile">
        <div class="flex items-center justify-center h-64">
          <font-awesome-icon
            icon="fa-duotone fa-spinner-third"
            class="h-10 w-10"
            spin
          />
        </div>
      </div>
    </div>

    <div class="flex items-center">
      <prime-checkbox
        input-id="isDocumentRead"
        v-model="isDocumentRead"
        class="mr-2"
        :binary="true"
      />
      <label for="isDocumentRead">
        {{ capitalize(t("i_confirm_that_i_have_read_the_document")) }}
      </label>
    </div>

    <div class="flex items-center">

      <prime-button
        size="small"
        class="!rounded-2xl w-full"
        :disabled="!isDocumentRead"
        :label="t('sign').toUpperCase()"
        :loading="loading"
        @click="signDocument"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { capitalize, onMounted, ref } from "vue";
import { useCheckInStore } from "@/stores";
import { useManagerApi } from "@/api";
import { useToast } from "primevue/usetoast";
import * as PDFJS from "pdfjs-dist";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { notifyError } from "@/utils";
import { SHOW_SIGNED_DOCUMENTS } from "@/constants";

PDFJS.GlobalWorkerOptions.workerSrc = "../../pdf.worker.mjs";

const { t } = useI18n();
const router = useRouter();
const toast = useToast();

const checkInStore = useCheckInStore();

const loadingFile = ref(false);
const loading = ref(false);

const uuid = router.currentRoute.value.params.uuid;

const isDocumentRead = ref(false);

const documentUrl = ref("");
const pdfDoc = ref(null);
const pageNum = ref(1);
const pageRendering = ref(false);
const pageNumPending = ref(null);
const MIN_SCALE = 1.6;
const scale = ref(MIN_SCALE);
const canvas = ref(null);
const ctx = ref(null);
const pageCount = ref(null);
const buttonRemoveZoomDisabled = ref(true);
const buttonAddZoomDisabled = ref(false);

const getDocumentToSign = async () => {
  loadingFile.value = true;

  useManagerApi()
    .get(`/nick-telemedicine/check-in-steps/${uuid}/get-document-to-sign`)
    .then(({ data }) => {
      loadPdf(data.data.document_url);
    })
    .catch(() => {
      notifyError(toast, capitalize(t("internal_server_error")), 5000);
    })
    .finally(() => {
      loadingFile.value = false;
    });
};

const signDocument = async () => {
  loading.value = true;

  useManagerApi()
    .post(`/nick-telemedicine/check-in-steps/${uuid}/sign-document`)
    .then(() => {
      checkInStore.changeCurrentStep(SHOW_SIGNED_DOCUMENTS);
    })
    .catch(() => {
      notifyError(toast, capitalize(t("internal_server_error")), 5000);
    })
    .finally(() => {
      loading.value = false;
    });
};

const loadPdf = (pdfUrl) => {
  documentUrl.value = pdfUrl;

  canvas.value = document.getElementById("pdf-viewer");
  ctx.value = canvas.value.getContext("2d");

  PDFJS.getDocument(documentUrl.value).promise.then((_pdfDoc) => {
    pdfDoc.value = () => _pdfDoc;

    pageCount.value = _pdfDoc.numPages;

    renderPage(pageNum.value);
  });
};

const renderPage = async (num) => {
  pageRendering.value = true;

  pdfDoc
    .value()
    .getPage(num)
    .then((page) => {
      const localScale = scale.value;
      const viewport = page.getViewport({ scale: localScale });

      canvas.value.height = viewport.height;
      canvas.value.width = viewport.width;

      const renderContext = {
        canvasContext: ctx.value,
        viewport,
      };

      page.render(renderContext).promise.then(() => {
        pageRendering.value = false;

        if (null !== pageNumPending.value) {
          renderPage(pageNumPending);
          pageNumPending.value = null;
        }
      });

      pageNum.value = num;
    });

  setButtonsState();
};

const setButtonsState = () => {
  buttonRemoveZoomDisabled.value = scale.value <= MIN_SCALE;
  buttonAddZoomDisabled.value = scale.value >= 3;
};

const zoomIn = () => {
  scale.value += 0.2;
  renderPage(pageNum.value);
};

const zoomOut = () => {
  scale.value -= 0.2;
  renderPage(pageNum.value);
};

onMounted(async () => {
  checkInStore.changeStepTitle("sign_the_document");
  await getDocumentToSign();
});
</script>
