<template>
  <schedule-session />
  <div id="nick-saude" class="h-full outline-none outline-0">
    <div v-if="hydrating || loading" class="min-h-screen bg-white">
      <div class="loading-container">
        <font-awesome-icon
          icon="fa-duotone fa-spinner-third"
          class="h-10 w-10"
          spin
        />
      </div>
    </div>

    <RouterView v-else-if="!hydrating && !loading" />
  </div>
  <prime-toast :position="toastPosition" />

  <reload-prompt />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import { RouterView } from "vue-router";
import { useApi } from "@/api";
import ReloadPrompt from "@/components/reload-prompt.vue";
import { DONA_HELENA_IDENTIFIER, useAppStore } from "@/stores";
import ScheduleSession from "@/views/schedules/components/schedule-session.vue";

const isMobile = window.innerWidth <= 768;
const toastPosition = isMobile ? "bottom-center" : "bottom-right";

const api = useApi("front");

const appStore = useAppStore();
const {
  hydrating,
  clientIdentifier,
  logo,
  customApp,
  customClientId,
  customClientModules,
} = storeToRefs(appStore);

const loading = ref(false);

const getSubdomain = (url) => {
  let domain = url;
  if (url.includes("://")) {
    domain = url.split("://")[1];
  }
  return domain.split(".")[0].replace("app-", "").split("-")[0];
};

onMounted(async () => {
  loading.value = true;

  if (clientIdentifier.value === null) {
    const subdomain = getSubdomain(window.location.href);

    clientIdentifier.value =
      subdomain === DONA_HELENA_IDENTIFIER ? subdomain : "app";
  }

  setTitle();
  setDescription();
  setIcons();
  setManifest();
  await setTheme();

  loading.value = false;
});

const setTitle = () => {
  document.title =
    clientIdentifier.value === DONA_HELENA_IDENTIFIER
      ? "Dona Helena"
      : "Nick Saúde";
};

const setDescription = () => {
  const description =
    clientIdentifier.value === DONA_HELENA_IDENTIFIER
      ? "Hospital Dona Helena"
      : "Nick Saúde App";

  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", description);
};

const setIcons = () => {
  let headTitle = document.querySelector("head");

  let icons = [
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: `/${clientIdentifier.value}/apple-touch-icon.png`,
    },
    {
      rel: "icon",
      sizes: "32x32",
      href: `/${clientIdentifier.value}/favicon-32x32.png`,
    },
    {
      rel: "icon",
      sizes: "16x16",
      href: `/${clientIdentifier.value}/favicon-16x16.png`,
    },
  ];

  icons.forEach(function (icon) {
    let linkElement = document.createElement("link");
    linkElement.setAttribute("rel", icon.rel);
    linkElement.setAttribute("sizes", icon.sizes);
    linkElement.setAttribute("href", icon.href);
    headTitle.appendChild(linkElement);
  });

  let linkElement = document.createElement("link");
  linkElement.setAttribute("rel", "mask-icon");
  linkElement.setAttribute(
    "href",
    `/${clientIdentifier.value}/safari-pinned-tab.svg`
  );
  linkElement.setAttribute("color", "#5bbad5");
  headTitle.appendChild(linkElement);
};

const setManifest = () => {
  document
    .querySelector("#app-manifest-placeholder")
    .setAttribute("href", `/${clientIdentifier.value}/manifest.json`);
};

const setTheme = async () => {
  if (clientIdentifier.value === DONA_HELENA_IDENTIFIER) {
    customApp.value = true;

    // TODO - Por agora foi criado um tema customizado, futuramente as configurações do tema virão do backend
    import("./assets/themes/donahelena/theme.scss");

    await fetchClientThemeConfiguration();
  } else {
    import("./assets/themes/tailwind/tailwind-light/theme.scss");
  }
};

const fetchClientThemeConfiguration = async () => {
  await api
    .get(`clients/${clientIdentifier.value}/theme-configuration`)
    .then(({ data }) => {
      if (data.data?.logo !== null) {
        logo.value = data.data.logo;
      }
      customClientId.value = data.data.id;
      customClientModules.value = data.data.modules;
    });
};
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-30%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.custom-badge.p-badge,
.custom-badge .p-badge {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background-color: #fff;
  height: 0.93rem;
  min-height: 0.93rem;
  min-width: 0.93rem;
  font-size: 0.55rem;
  line-height: 0.8rem;
  padding: 0;
}

.custom-badge.p-overlay-badge.p-badge,
.custom-badge.p-overlay-badge .p-badge {
  top: 0.3rem;
  right: 0.2rem;
}
</style>
