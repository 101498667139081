<template>
  <form @submit="onSubmit" class="flex flex-col justify-between">
    <div class="pt-4 grid grid-cols-2 gap-x-2 gap-y-3">
      <div class="space-y-2 col-span-2">
        <label for="doctor_name" class="text-sm font-medium">
          {{ capitalize(t("name")) }}
        </label>

        <prime-input-text
          id="name"
          class="w-full"
          v-model="name"
          :class="{ 'p-invalid': errors['name'] }"
        />

        <p v-if="errors['name']" class="text-sm text-red-600">
          {{ errors["name"] }}
        </p>
      </div>

      <div class="space-y-2 col-span-2">
        <label for="cpf" class="text-sm font-medium">
          {{ t("cpf") }}
        </label>

        <prime-input-mask
          id="cpf"
          name="cpf"
          v-model="cpf"
          mask="999.999.999-99"
          :useGrouping="false"
          class="w-full p-inputtext-sm"
          :class="{ 'p-invalid': errors['cpf'] }"
        />

        <small class="p-error">{{ errors["cpf"] }}</small>
      </div>

      <div class="space-y-2">
        <label
          for="birthday"
          class="text-sm font-medium leading-5 text-gray-700"
        >
          {{ capitalize(t("birthday")) }}
        </label>
        <prime-input-mask
          id="birthday"
          v-model="birthday"
          mask="99/99/9999"
          required
          :useGrouping="false"
          class="w-full"
          :class="{ 'p-invalid': errors['birthday'] }"
        />
        <small class="p-error">{{ errors["birthday"] }}</small>
      </div>

      <div class="space-y-2">
        <label for="phone_number" class="text-sm font-medium">
          {{ capitalize(t("phone_number")) }}
        </label>
        <prime-input-mask
          id="phone_number"
          v-model="phoneNumber"
          mask="(99) 9 9999-9999"
          required
          :useGrouping="false"
          class="w-full"
          :class="{ 'p-invalid': errors['phone_number'] }"
        />
        <small class="p-error">{{ errors["phone_number"] }}</small>
      </div>

      <div class="space-y-2 col-span-2" v-if="customApp">
        <label for="client_responsible_type_id" class="text-sm font-medium">
          {{ capitalize(t("legal_guardian_responsible_type")) }}
        </label>

        <prime-dropdown
          id="client_responsible_type_id"
          name="client_responsible_type_id"
          v-model="clientResponsibleTypeId"
          :options="clientResponsibleTypes"
          optionLabel="description"
          optionValue="id"
          class="w-full p-inputtext-sm"
          :class="{ 'p-invalid': errors['client_responsible_type_id'] }"
        />

        <small class="p-error">
          {{ errors["client_responsible_type_id"] }}
        </small>
      </div>
    </div>

    <div class="text-center col-span-2 mt-16">
      <prime-button
        id="btn-register"
        :label="t('register').toUpperCase()"
        type="submit"
        size="small"
        class="!rounded-2xl w-full"
      />
    </div>
  </form>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { capitalize } from "@/utils";
import * as yup from "yup";
import { useField, useForm } from "vee-validate";
import { useManagerApi } from "@/api";
import { onMounted, ref } from "vue";
import { format, parse } from "date-fns";
import { useAppStore } from "@/stores";
import { storeToRefs } from "pinia";

const emit = defineEmits(["created"]);

const { t } = useI18n();
const router = useRouter();

const appStore = useAppStore();
const { customApp } = storeToRefs(appStore);

const clientResponsibleTypes = ref([]);

const validationSchema = yup.object({
  name: yup.string().nullable().required(),
  cpf: yup.string().nullable().required(),
  birthday: yup
    .string()
    .nullable()
    .required()
    .matches(
      /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
      capitalize(t("invalid_date_format"))
    ),
  phone_number: yup.string().nullable().required(),
  client_responsible_type_id: yup
    .number()
    .nullable()
    .when([], {
      is: () => customApp.value,
      then: (schema) => schema.required(),
    }),
});

const initialValues = {
  name: null,
  cpf: null,
  birthday: null,
  phone_number: null,
  client_responsible_type_id: null,
};

const { handleSubmit, errors } = useForm({
  validationSchema,
  initialValues,
});

const { value: name } = useField("name");
const { value: cpf } = useField("cpf");
const { value: birthday } = useField("birthday");
const { value: phoneNumber } = useField("phone_number");
const { value: clientResponsibleTypeId } = useField(
  "client_responsible_type_id"
);

const onSubmit = handleSubmit(onSuccess);

function onSuccess() {
  let birthdayFormatted = parse(birthday.value, "dd/MM/yyyy", new Date());

  birthdayFormatted = format(birthdayFormatted, "yyyy-MM-dd");

  useManagerApi()
    .post("nick-patients/patient-legal-guardian", {
      name: name.value,
      cpf: cpf.value,
      birthday: birthdayFormatted,
      phone_number: phoneNumber.value,
      client_responsible_type_id: clientResponsibleTypeId.value,
    })
    .then(({ data }) => {
      emit("created", {
        ...data,
        client_responsible_type_id: clientResponsibleTypeId.value,
      });
    });
}

async function fetchClientResponsibleTypes() {
  await useManagerApi()
    .get("responsible-types")
    .then(({ data }) => {
      clientResponsibleTypes.value = data.data;
    });
}

onMounted(() => {
  fetchClientResponsibleTypes();
});
</script>
