import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faStethoscope as farStethoscope,
  faTruckMedical as farTruckMedical,
  faChevronDown as farChevronDown,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faChevronUp as farChevronUp,
  faBell as farBell,
  faBellOn as farBellOn,
  faBellSlash as farBellSlash,
  faBars as farBars,
  faXmark as farXmark,
  faXmarkCircle as farXmarkCircle,
  faDoorOpen as farDoorOpen,
  faPrint as farPrint,
  faFloppyDisk as farFloppyDisk,
  faExclamation as farExclamation,
  faExclamationTriangle as farExclamationTriangle,
  faQuestion as farQuestion,
  faCircleXmark as farCircleXmark,
  faLocationExclamation as farLocationExclamation,
  faLocationDotSlash as farLocationDotSlash,
  faFaceSmile as farFaceSmile,
  faFaceGrin as farFaceGrin,
  faFaceMeh as farFaceMeh,
  faFaceLaughBeam as farFaceLaughBeam,
  faFaceFrown as farFaceFrown,
  faFaceDisappointed as farFaceDisappointed,
  faPaperclipVertical as farPaperclipVertical,
  faFilePdf as farFilePdf,
  faStarOfLife as farStarOfLife,
  faCamera as farCamera,
  faCheck as farCheck,
  faCheckDouble as farCheckDouble,
  faCirclePhone as farCirclePhone,
  faTrashCan as farTrashCan,
  faFaceViewfinder as farFaceViewfinder,
  faCircleCheck as farCircleCheck,
  faPlus as farPlus,
  faCartPlus as farCartPlus,
  faCircleQuestion as farCircleQuestion,
  faTrashXmark as farTrashXmark,
  faSquareArrowDownRight as farSquareArrowDownRight,
  faArrowRight as farArrowRight,
  faPaperPlane as farPaperPlane,
  faArrowsRotate as farArrowsRotate,
  faMagnifyingGlassPlus as farMagnifyingGlassPlus,
  faMagnifyingGlassMinus as farMagnifyingGlassMinus,
  faTimes as farTimes,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faCircleQuestion as fasCircleQuestion,
  faBell as fasBell,
  faCircleCheck as fasCircleCheck,
  faCircleExclamation as fasCircleExclamation,
  faLightbulbOn as fasLightbulbOn,
  faCamera as fasCamera,
  faShirt as fasShirt,
  faChevronRight as fasChevronRight,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faQrcode as falQrcode,
  faMapMarkerAlt as falMapMarkerAlt,
  faClipboardUser as falClipboardUser,
  faClipboardQuestion as falClipboardQuestion,
  faCamera as falCamera,
  faCheck as falCheck,
  faCheckDouble as falCheckDouble,
  faCalendarClock as falCalendarClock,
  faLocationDot as falLocationDot,
  faHospitals as falHospitals,
  faHourglassClock as falHourglassClock,
  faCalendarDays as falCalendarDays,
  faClock as falClock,
  faPenToSquare as falPenToSquare,
  faQuestionCircle as falQuestionCircle,
  faCircleExclamation as falCircleExclamation,
  faUserDoctor as falUserDoctor,
  faImage as falImage,
  faNotesMedical as falNotesMedical,
  faCalendarPlus as falCalendarPlus,
  faFile as falFile,
  faArrowDownToLine as falArrowDownToLine,
  faCircleCheck as falCircleCheck,
  faLaptopMedical as falLaptopMedical,
} from "@fortawesome/pro-light-svg-icons";

import {
  faSpinnerThird as fadSpinnerThird,
  faCircleUser as fadCircleUser,
  faStarOfLife as fadStarOfLife,
  faCamera as fadCamera,
  faCheck as fadCheck,
  faCheckDouble as fadCheckDouble,
  faTriangleExclamation as fadTriangleExclamation,
} from "@fortawesome/pro-duotone-svg-icons";

import {
  faMapMarker as fasMapMarker,
  faCartPlus as fasCartPlus,
} from "@fortawesome/pro-solid-svg-icons";

export const registerIcons = () => {
  library.add(
    farStethoscope,
    farTruckMedical,
    farChevronDown,
    farChevronLeft,
    farChevronRight,
    farChevronUp,
    falQrcode,
    falMapMarkerAlt,
    falClipboardUser,
    falClipboardQuestion,
    fadSpinnerThird,
    fadCircleUser,
    fasBell,
    farBell,
    farBellOn,
    farBellSlash,
    farBars,
    farXmark,
    farXmarkCircle,
    farDoorOpen,
    farPrint,
    farFloppyDisk,
    farExclamation,
    farExclamationTriangle,
    farQuestion,
    farCircleXmark,
    farLocationExclamation,
    farLocationDotSlash,
    farFaceSmile,
    farFaceGrin,
    farFaceMeh,
    farFaceLaughBeam,
    farFaceFrown,
    farFaceDisappointed,
    farPaperclipVertical,
    farFilePdf,
    farStarOfLife,
    fadStarOfLife,
    farCamera,
    falCamera,
    fadCamera,
    farCheck,
    falCheck,
    fadCheck,
    farCheckDouble,
    falCheckDouble,
    fadCheckDouble,
    farCirclePhone,
    fasCircleQuestion,
    farTrashCan,
    fasMapMarker,
    farFaceViewfinder,
    farCircleCheck,
    falCalendarClock,
    falLocationDot,
    falHospitals,
    falHourglassClock,
    falCalendarDays,
    falClock,
    falPenToSquare,
    farPlus,
    fadTriangleExclamation,
    falQuestionCircle,
    falCircleExclamation,
    farCartPlus,
    fasCartPlus,
    falUserDoctor,
    falImage,
    farCircleQuestion,
    farTrashXmark,
    farSquareArrowDownRight,
    farArrowRight,
    falNotesMedical,
    falCalendarPlus,
    fasCircleExclamation,
    fasLightbulbOn,
    fasCamera,
    fasShirt,
    fasCircleCheck,
    falFile,
    falArrowDownToLine,
    falCircleCheck,
    falLaptopMedical,
    farPaperPlane,
    farArrowsRotate,
    farMagnifyingGlassPlus,
    farMagnifyingGlassMinus,
    farArrowsRotate,
    farTimes,
    fasChevronRight
  );
};
