import { defineStore } from "pinia";
import { useApi } from "@/api";

export const useJitsiStore = defineStore({
  id: "jitsi",

  state: () => ({
    optionsMeet: null,
    loading: true,
    error: null,
  }),

  actions: {
    setOptionsMeet(payload) {
      this.optionsMeet = {
        jwt: payload.jitsi_token ?? "",
        roomName: payload.jitsi_room_name,
      };
    },
    async initializeMeet(uuid) {
      this.loading = true;

      try {
        const { data } = await useApi("front").get(
          `telemedicine/video-call/${uuid}`
        );
        this.setOptionsMeet(data.data);
      } catch (error) {
        this.error = error;
        this.loading = false;
        throw error;
      } finally {
        this.loading = false;
      }
    },
  },
});
