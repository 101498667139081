import { useAppStore, usePatientStore, useSchedulesStore } from "@/stores";

export function useStores(stores = [usePatientStore, useSchedulesStore]) {
  return stores.map((useStore) => useStore());
}

export async function hydrate() {
  const stores = useStores();

  const appStore = useAppStore();
  const patientStore = usePatientStore();

  if (appStore.hydrated) return;
  if (appStore.hydrating) return;

  appStore.hydrating = true;

  try {
    if (patientStore.hydrated === false) {
      await patientStore.hydrate();
    }

    const hydratedStores = ["patientStore"];
    await Promise.all(
      stores
        .filter(({ $id }) => !hydratedStores.includes($id))
        .filter((store) => store.hydrated === false)
        .map((store) => store.hydrate?.())
    );
  } catch (e) {
    appStore.error = e;
  } finally {
    appStore.hydrating = false;
  }

  appStore.hydrated = true;
}

export async function dehydrate(stores = useStores()) {
  const appStore = useAppStore();

  if (appStore.hydrated === false) return;

  for (const store of stores) {
    await store.dehydrate?.();
  }

  appStore.hydrated = false;
}
