import { defineStore } from "pinia";
import { useApi, useManagerApi } from "@/api";
import { formatTitle } from "@/utils";
import { useStorage } from "@vueuse/core";

const CONSULTATION = 1;
const EXAM = 2;

export default {
  CONSULTATION,
  EXAM,
};

export const useSchedulesStore = defineStore({
  id: "schedulesStore",
  state: () => ({
    schedules: [],
    schedule: null,
    toReschedule: useStorage("toReschedule", null, localStorage, {
      serializer: {
        read: (v) => (v ? JSON.parse(v) : null),
        write: (v) => JSON.stringify(v),
      },
    }),
    loading: false,
    error: null,
    hydrated: false,
  }),
  actions: {
    async hydrate() {
      this.loading = true;

      try {
        const { data } = await useManagerApi().get(
          "/fast-checkin/patient-schedules"
        );

        const schedules = data.data;

        this.schedules = schedules.map((schedule) => {
          return {
            ...schedule,
            description: formatTitle(
              schedule.speciality_type?.type ||
                schedule.client_speciality?.title ||
                schedule.medical_procedure_name
            ),
          };
        });

        this.hydrated = true;
      } catch (e) {
        this.error = e;
        throw e;
      } finally {
        this.loading = false;
      }
    },
    async dehydrate() {
      this.$reset();
    },
    async checkin(uuid) {
      this.loading = true;

      try {
        await useApi("front").post(
          `/patient/auto-checkin/schedule/${uuid}/presence`
        );

        await this.hydrate();
      } catch (e) {
        this.error = e;
        throw e;
      } finally {
        this.loading = false;
      }
    },
    setToReschedule(id, uuid) {
      this.toReschedule = { id, uuid };
    },
    resetToReschedule() {
      this.toReschedule = null;
    },
  },
  getters: {
    attended(state) {
      return state.schedules.filter(
        (schedule) => schedule.appointment_status === "attended"
      );
    },
    consultations(state) {
      return state.schedules.filter(
        (schedule) => schedule.procedure_id === CONSULTATION
      );
    },
    consultationsHistory(state) {
      return state.schedules.filter(
        (schedule) =>
          schedule.procedure_id === CONSULTATION && schedule.is_past === true
      );
    },
    exams(state) {
      return state.schedules.filter(
        (schedule) => schedule.procedure_id === EXAM
      );
    },
    allItems(state) {
      return state.schedules;
    },
    history(state) {
      return state.schedules.filter((item) => item.is_past === true);
    },
    upcoming(state) {
      return state.schedules.filter((item) => item.is_past === false);
    },
    getItem(state) {
      return (uuid) =>
        state.schedules.find((schedule) => schedule.uuid === uuid);
    },
    readyForCheckin(state) {
      return state.schedules.filter(
        (schedule) => schedule.is_ready_for_reception_check_in
      );
    },
  },
});
