import { csrfToken, useApi } from "@/api";
import { useAppStore, useCartStore, usePatientStore } from "@/stores";
import { dehydrate, hydrate } from "@/hydrate";
import { useRouter } from "vue-router";

export async function login({ code, password }) {
  const appStore = useAppStore();
  const cartStore = useCartStore();

  await csrfToken();

  const response = await useApi("front").post("auth/login", {
    code,
    password,
    force_full_birthdate: true,
    current_cart_id: cartStore.cart?.id,
  });
  const { two_factor } = response.data;

  appStore.twoFactor = two_factor;
}

export async function forgotPassword({ code }) {
  const appStore = useAppStore();

  await csrfToken();

  const response = await useApi("front").post("patient/forgot-password", {
    code,
    force_full_birthdate: true,
  });
  const { two_factor, has_facial_biometrics } = response.data.data;

  appStore.twoFactor = two_factor;
  appStore.forceShowPatientCard = true;

  return has_facial_biometrics;
}

export async function loginQrCode(uuid) {
  const appStore = useAppStore();
  const cartStore = useCartStore();

  await csrfToken();

  const response = await useApi("front").post("auth/login/qrcode", {
    qrcode_uuid: uuid,
    force_full_birthdate: true,
    current_cart_id: cartStore.cart?.id,
  });

  const { two_factor } = response.data;

  appStore.twoFactor = two_factor;
}

export async function loginFaceRecognition(urlImage, origin) {
  const appStore = useAppStore();
  const cartStore = useCartStore();

  const formData = new FormData();
  const face = await fetch(urlImage).then((r) => r.blob());

  await csrfToken();
  formData.append("face", face);
  formData.append("current_cart_id", cartStore.cart?.id);

  const response = await useApi("front").post(
    "auth/login/face-recognition",
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  const { two_factor } = response.data;

  appStore.twoFactor = two_factor;
  appStore.forceShowPatientCard = origin !== "login";
}

export async function loginTwoFactor({ value, origin }) {
  const appStore = useAppStore();
  const cartStore = useCartStore();

  await csrfToken();

  const { field, token } = appStore.twoFactor;
  const body = { field, value };
  body.current_cart_id = cartStore.cart?.id;

  if (field === "birthday") {
    body.origin = origin;
  }

  const response = await useApi("front").post("auth/login/two-factor", body, {
    headers: { Authorization: `Bearer ${token}` },
  });

  if (field === "birthday" && origin === "register") {
    const { two_factor } = response.data;
    appStore.twoFactor = two_factor;
  } else {
    await hydrate();
    appStore.authenticated = true;
  }
}

let isRefreshing = false;
let firstRefresh = true;

export async function refresh() {
  const appStore = useAppStore();
  const patientStore = usePatientStore();

  if (firstRefresh) firstRefresh = false;

  if (isRefreshing) return;

  isRefreshing = true;

  try {
    await patientStore.hydrate();

    appStore.authenticated = true;
  } catch (e) {
    /* empty */
  } finally {
    isRefreshing = false;
  }
}

export async function logout() {
  const router = useRouter();
  const appStore = useAppStore();

  await csrfToken();

  await useApi("front").post("auth/logout");

  appStore.authenticated = false;
  appStore.twoFactor = null;

  await dehydrate();

  return router.push({ name: "login" });
}

export async function loginToken(token) {
  const appStore = useAppStore();

  await csrfToken();

  await useApi("front")
    .post(
      "auth/login/token",
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(async () => {
      await hydrate();

      appStore.authenticated = true;
    })
    .catch((error) => {
      throw error;
    });
}
