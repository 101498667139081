import { defineStore } from "pinia";
import { addMinutes } from "date-fns";
import { LOCAL_STORAGE } from "@/constants";
import { useCartStore } from "@/stores";

export const useScheduleSessionStore = defineStore({
  id: "scheduleSessionStore",
  state: () => ({
    session: {
      started_in: null,
      expire_in: null,
    },
    hasStoredSession: false,
    expired: false,
    hydrated: false,
  }),
  actions: {
    hydrate() {
      const storedSession = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE.SCHEDULE_SESSION)
      );

      this.hydrated = true;
      this.session = storedSession;
      this.hasStoredSession = !!storedSession;
      this.checkIfExpired();
    },
    checkIfExpired() {
      if (false === this.hydrated) {
        this.hydrate();
      }

      if (false === this.hasStoredSession) {
        this.expired = false;
        return false;
      }

      const currentTime = new Date().getTime();
      const expireIn = this.session?.expire_in;
      this.expired = expireIn < currentTime;

      return this.expired;
    },
    percentageTimeLeft() {
      if (false === this.hydrated) {
        this.hydrate();
      }

      if (false === this.hasStoredSession) {
        return 0;
      }

      const currentTime = new Date().getTime();
      const startedIn = this.session?.started_in || 0;
      const expireIn = this.session?.expire_in || 0;

      const totalTime = expireIn - startedIn;
      const timeLeft = expireIn - currentTime;

      if (0 === totalTime) {
        return 0;
      }

      const percentageLeft = (timeLeft * 100) / totalTime;

      return Math.max(percentageLeft, 0);
    },
    async startSession() {
      const cartStore = useCartStore();
      await cartStore.createCart();

      if (cartStore.cart !== null) {
        localStorage.setItem(
          LOCAL_STORAGE.SCHEDULE_SESSION,
          JSON.stringify({
            started_in: new Date().getTime(),
            expire_in: addMinutes(
              new Date(),
              cartStore.cart.minutes_to_expire
            ).getTime(),
          })
        );
        this.hydrate();
      }
    },
    async resetSession() {
      const cartStore = useCartStore();
      cartStore.resetCart();
      localStorage.removeItem(LOCAL_STORAGE.SCHEDULE_SESSION);
      this.$reset();
    },
  },
});
