export default [
	'2D',
	'3D',
	'4WD',
	'API',
	'BASIC',
	'BIOS',
	'CCTV',
	'CC',
	'CCV',
	'CD',
	'CD-ROM',
	'COBOL',
	'CIA',
	'CMS',
	'CSS',
	'CSV',
	'CV',
	'DIY',
	'DVD',
	'DB',
	'DNA',
	'E3',
	'EIN',
	'ESPN',
	'FAQ',
	'FTP',
	'FPS',
	'FORTRAN',
	'FBI',
	'HTML',
	'HTTP',
	'ID',
	'IP',
	'ISO',
	'JS',
	'JSON',
	'LASER',
	'M2M',
	'M2MM',
	'M2O',
	'MMORPG',
	'NAFTA',
	'NASA',
	'NDA',
	'O2M',
	'PDF',
	'PHP',
	'POP',
	'RAM',
	'RNGR',
	'ROM',
	'RPG',
	'RTFM',
	'RTS',
	'SCUBA',
	'SITCOM',
	'SKU',
	'SMTP',
	'SQL',
	'SSN',
	'SWAT',
	'TBS',
	'TTL',
	'TV',
	'TNA',
	'UI',
	'URL',
	'USB',
	'UWP',
	'VIP',
	'W3C',
	'WYSIWYG',
	'WWW',
	'WWE',
	'WWF',
  'CPF',
  'CNPJ',
  'CRA',
  'SMS',
  'CM',
  'SAMS',
  'QR',
  'SADT',
  'COVID',
  'NPS',
];
