export function isEmpty(value) {
  if (value instanceof Object) {
    return Object.keys(value).length === 0;
  }

  return value == null || value === undefined;
}

export function notEmpty(value) {
  return value !== null && value !== undefined;
}
