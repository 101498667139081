<template>
  <div class="text-sm text-gray-500">
    {{ capitalize(t("face_capture_description")) }}
  </div>

  <div class="py-2 h-[calc(100%-100px)]">
    <div v-show="!currentSnapshot" class="h-full flex flex-col justify-between">
      <video autoplay ref="video" id="video" class="rounded-2xl"></video>
      <div class="text-center">
        <prime-button
          class="w-full !rounded-full justify-content-center"
          :loading="loading"
          @click="snapshot"
        >
          <font-awesome-icon icon="fa-regular fa-camera" class="mr-2" />
          {{ capitalize(t("capture")) }}
        </prime-button>
      </div>
    </div>

    <div v-show="currentSnapshot" class="h-full flex flex-col justify-between">
      <img :src="currentSnapshot" alt="teste" class="rounded-2xl" />
      <div class="w-full text-center space-y-2">
        <prime-button
          class="w-full !rounded-full justify-content-center"
          :loading="loading"
          @click="submit"
        >
          <font-awesome-icon icon="fa-regular fa-paper-plane" class="mr-2" />
          {{ capitalize(t("send")) }}
        </prime-button>
        <prime-button
          class="w-full !rounded-full justify-content-center"
          severity="danger"
          :loading="loading"
          @click="clearSnapShot"
        >
          <font-awesome-icon icon="fa-regular fa-arrows-rotate" class="mr-2" />
          {{ capitalize(t("try_again")) }}
        </prime-button>
      </div>
    </div>

    <canvas ref="canvas" id="canvas" class="hidden"></canvas>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { capitalize, onMounted, ref } from "vue";
import { useCheckInStore } from "@/stores";
import { useManagerApi } from "@/api";
import { useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import { notifyError, notifySuccess } from "@/utils";
import { i18n } from "@/lang";
import * as faceapi from "face-api.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { SIGN_DOCUMENTS } from "@/constants";

const { t } = useI18n();

const checkInStore = useCheckInStore();
const route = useRoute();
const toast = useToast();

const currentSnapshot = ref(null);
const currentSnapshotFile = ref(null);
const stream = ref(null);
const canvas = ref(null);
const video = ref(null);
const loading = ref(false);

const uuid = route.params.uuid;

async function snapshot() {
  loading.value = true;
  canvas.value.width = 1920;
  canvas.value.height = 1080;
  canvas.value.getContext("2d")?.drawImage(video.value, 0, 0, 1920, 1080);

  canvas.value.toBlob(
    async (blob) => {
      const img = new Image();
      img.src = URL.createObjectURL(blob);

      const detections = await faceapi.detectAllFaces(
        img,
        new faceapi.TinyFaceDetectorOptions()
      );

      if (detections.length === 0) {
        notifyError(toast, capitalize(i18n.global.t("no_face_detected")), 5000);
        loading.value = false;

        return;
      }

      if (detections.length > 1) {
        notifyError(
          toast,
          capitalize(i18n.global.t("multiple_faces_detected")),
          5000
        );
        loading.value = false;

        return;
      }

      currentSnapshotFile.value = new File([blob], "snapshot.jpg", {
        type: "image/jpeg",
        lastModified: Date.now(),
      });

      currentSnapshot.value = URL.createObjectURL(blob);
      loading.value = false;
    },
    "image/jpeg",
    0.95
  );
}

function clearSnapShot() {
  currentSnapshot.value = null;
}

function submit() {
  loading.value = true;

  const formData = new FormData();
  formData.append("image", currentSnapshotFile.value);

  useManagerApi()
    .post(
      `nick-telemedicine/check-in-steps/${uuid}/facial-biometrics-capture-for-signature`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then(() => {
      notifySuccess(
        toast,
        capitalize(i18n.global.t("successfully_capture_face")),
        5000
      );

      checkInStore.changeCurrentStep(SIGN_DOCUMENTS);
    })
    .catch(() => {
      notifyError(toast, capitalize(i18n.global.t("unexpected_error")), 5000);
    })
    .finally(() => {
      loading.value = false;
    });
}

onMounted(async () => {
  checkInStore.changeStepTitle("face_capture");

  await faceapi.nets.tinyFaceDetector.loadFromUri("/js/face-api/models");
  await faceapi.nets.faceLandmark68Net.loadFromUri("/js/face-api/models");

  stream.value = await navigator.mediaDevices.getUserMedia({
    video: {
      width: 1920,
      height: 1080,
      deviceId: {},
    },
    audio: false,
  });

  video.value.srcObject = stream.value;
});
</script>
