<template>
  <div class="text-sm text-gray-500 mb-4">
    {{ capitalize(t("select_legal_guardian_description")) }}
  </div>
  <div
    class="rounded-lg bg-white shadow-md border border-inherit w-full col-span-4 py-2 overflow-y-auto h-4/6"
  >
    <div
      v-for="legalGuardian in legalGuardians"
      :key="legalGuardian.id"
      class="flex hover:cursor-pointer transform transition duration-500 ease-in-out hover:scale-125 border-b border-slate-200 last:!border-0 mx-3 px-2"
      :class="{
        'bg-primary-100 !border-2 !border-sky-600 rounded-lg mx-0 px-5':
          selectedLegalGuardian?.id === legalGuardian.id,
      }"
      @click="selectedLegalGuardian = legalGuardian"
    >
      <div class="py-2 flex-1">
        <div class="font-bold">{{ legalGuardian.name }}</div>
        <div>
          {{
            legalGuardian.client_responsible_type?.responsible_type?.description
          }}
        </div>
      </div>
      <div class="self-center">
        <font-awesome-icon icon="fa-solid fa-chevron-right" class="h-5 w-5" />
      </div>
    </div>
  </div>

  <div class="text-center w-full space-y-2 my-4">
    <prime-button
      class="w-full max-w-96 !rounded-full p-button-primary"
      :label="capitalize(t('select_legal_guardian'))"
      :disabled="selectedLegalGuardian === null"
      :loading="loading"
      @click="selectLegalGuardian"
    />
    <prime-button
      class="w-full max-w-96 !rounded-full !p-button-secondary"
      outlined
      :label="capitalize(t('register_new_legal_guardian'))"
      @click="registerNewLegalGuardian"
    />
  </div>
</template>

<script setup>
import { capitalize } from "@/utils";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useCheckInStore, usePatientStore } from "@/stores";
import { onMounted, ref } from "vue";
import { useManagerApi } from "@/api";
import { LEGAL_GUARDIAN_CREATE } from "@/constants";

const { t } = useI18n();
const route = useRoute();

const uuid = route.params.uuid;

const checkInStore = useCheckInStore();
const patientStore = usePatientStore();

const legalGuardians = ref([]);
const selectedLegalGuardian = ref(null);
const loading = ref(false);

const fetchLegalGuardians = () => {
  useManagerApi()
    .get("/nick-telemedicine/check-in-steps/patient/legal-guardians/")
    .then(({ data }) => {
      legalGuardians.value = data;
    });
};

const selectLegalGuardian = () => {
  loading.value = true;
  useManagerApi()
    .post(
      `/nick-telemedicine/check-in-steps/patient/${uuid}/select-legal-guardian/`,
      {
        legal_guardian_id: selectedLegalGuardian.value.id,
        client_responsible_type_id:
          selectedLegalGuardian.value.client_responsible_type.id,
      }
    )
    .then(() => {
      checkInStore.getNextStep(uuid);
    })
    .finally(() => {
      loading.value = false;
    });
};

const registerNewLegalGuardian = () => {
  checkInStore.changeCurrentStep(LEGAL_GUARDIAN_CREATE);
};

onMounted(() => {
  checkInStore.changeStepTitle("legal_guardian");

  if (patientStore.patientAge >= 18) {
    checkInStore.getNextStep(uuid, true);
  }

  fetchLegalGuardians();
});
</script>
