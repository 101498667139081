import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

export const DONA_HELENA_IDENTIFIER = "donahelena";

export const useAppStore = defineStore({
  id: "appStore",
  state: () => ({
    hydrating: false,
    hydrated: false,
    authenticated: false,
    twoFactor: null,
    forceShowPatientCard: false,
    newlyRegistered: false,
    forgotPassword: false,
    error: null,
    clientIdentifier: null,
    logo: "/img/nick_logo.png",
    customApp: false,
    customClientId: null,
    customClientModules: [],
    redirectAfterLogin: useStorage("redirectAfterLogin", null, localStorage, {
      mergeDefaults: true,
    }),
  }),
  actions: {
    setRedirectAfterLogin(routeFullPath) {
      this.redirectAfterLogin = routeFullPath;
    },
    removeRedirectAfterLogin() {
      this.redirectAfterLogin = null;
    },
  },
});
