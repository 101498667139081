import prepositions from "./prepositions";
import conjunctions from "./conjunctions";
import acronyms from "./acronyms";
import specialCase from "./special-case";

function handleSpecialWords(str, index, words) {
  const lowercaseStr = str.toLowerCase();
  const uppercaseStr = str.toUpperCase();

  for (let i = 0; i < specialCase.length; i += 1) {
    if (specialCase[i].toLowerCase() === lowercaseStr) return specialCase[i];
  }

  if (acronyms.includes(uppercaseStr)) return uppercaseStr;

  // If the word is the first word in the sentence, but it's not a specially
  // cased word or an acronym, return the capitalized string
  if (index === 0) return str;

  // If the word is the last word in the sentence, but it's not a specially
  // cased word or an acronym, return the capitalized string
  if (index === words.length - 1) return str;

  if (prepositions.includes(lowercaseStr)) return lowercaseStr;
  if (conjunctions.includes(lowercaseStr)) return lowercaseStr;

  return str;
}

function combine(acc, str) {
  return `${acc} ${str}`;
}

export function capitalize(word) {
  word = word || "";
  return word.charAt(0).toUpperCase() + word.substring(1);
}

function decamelize(string) {
  return string
    .replace(/([a-z\d])([A-Z])/g, "$1_$2")
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1_$2")
    .toLowerCase();
}

export function formatTitle(title, separator) {
  if (!separator) separator = new RegExp("/s|-|_| ", "g");
  if (null === title || undefined === title) return "";

  return decamelize(title)
    .split(separator)
    .map(capitalize)
    .map(handleSpecialWords)
    .reduce(combine);
}
