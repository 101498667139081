import { defineStore } from "pinia";
import { query } from "js-query-builder";
import {
  CAPTURE_FACE_FOR_DIGITAL_SIGNATURE,
  CHECK_IN_TERMS,
  DOCUMENTS_DIGITAL_SIGNATURE,
  HEALTH_QUESTIONNAIRE,
  LEGAL_GUARDIAN_CREATE,
  PATIENT_COMPANIONS,
  SHOW_SIGNED_DOCUMENTS,
  SIGN_DOCUMENTS,
  TELEMEDICINE_GUIDELINES,
  WAITING_FOR_TELEMEDICINE,
} from "@/constants";
import { useApi, useManagerApi } from "@/api";
import CheckInTerms from "@/views/check-in-steps/check-in-terms.vue";
import GuidelinesView from "@/views/schedules/telemedicine/guidelines-view.vue";
import HealthQuestionnaire from "@/views/check-in-steps/health-questionnaire.vue";
import LegalGuardianCreate from "@/views/check-in-steps/legal-guardian-create.vue";
import WaitingForTelemedicine from "@/views/check-in-steps/waiting-for-telemedicine.vue";
import ListPatientLegalGuardians from "@/views/check-in-steps/list-patient-legal-guardians.vue";
import ShowDocumentToSign from "@/views/check-in-steps/sign-documents/show-document-to-sign.vue";
import ShowSignedDocuments from "@/views/check-in-steps/sign-documents/show-signed-documents.vue";
import AgreeToSignDocuments from "@/views/check-in-steps/sign-documents/agree-to-sign-documents.vue";
import FacialBiometricsCaptureForSignature from "@/views/check-in-steps/sign-documents/facial-biometrics-capture-for-signature.vue";

// Steps configured inside Nick Manager
const checkInSteps = [
  {
    type: HEALTH_QUESTIONNAIRE,
    component: HealthQuestionnaire,
  },
  {
    type: PATIENT_COMPANIONS,
    component: ListPatientLegalGuardians,
  },
  {
    type: DOCUMENTS_DIGITAL_SIGNATURE,
    component: AgreeToSignDocuments,
  },
  {
    type: CAPTURE_FACE_FOR_DIGITAL_SIGNATURE,
    component: FacialBiometricsCaptureForSignature,
  },
  {
    type: SIGN_DOCUMENTS,
    component: ShowDocumentToSign,
  },
  {
    type: SHOW_SIGNED_DOCUMENTS,
    component: ShowSignedDocuments,
  },
  {
    type: LEGAL_GUARDIAN_CREATE,
    component: LegalGuardianCreate,
  },
  {
    type: CHECK_IN_TERMS,
    component: CheckInTerms,
  },
];

// Virtual steps that can be shown after the steps configured inside Nick Manager
const virtualCheckInSteps = [
  {
    type: WAITING_FOR_TELEMEDICINE,
    component: WaitingForTelemedicine,
  },
  {
    type: TELEMEDICINE_GUIDELINES,
    component: GuidelinesView,
  },
];

export const useCheckInStore = defineStore({
  id: "checkInStore",
  state: () => ({
    currentStep: null,
    currentStepTitle: null,
    telemedicineRoomExists: false,
    loading: false,
  }),
  actions: {
    changeStepTitle(title = null) {
      this.currentStepTitle = title;
    },
    changeCurrentStep(stepType) {
      this.currentStep = null;
      this.currentStep = stepType;
    },
    getNextStep(uuid, forceSendCurrentStep = false) {
      this.loading = true;

      const urlBuilder = query(
        `/nick-telemedicine/check-in-steps/${uuid}/next-step`
      );

      if (forceSendCurrentStep) {
        urlBuilder.param("current_step_type", this.currentStep);
      }

      useManagerApi()
        .get(urlBuilder.build())
        .then(async ({ data }) => {
          this.currentStep = data?.type ?? null;

          if (this.currentStep === null) {
            await this.checkTelemedicineRoomExistence(uuid);

            if (this.telemedicineRoomExists) {
              this.changeCurrentStep(TELEMEDICINE_GUIDELINES);
            } else {
              this.changeCurrentStep(WAITING_FOR_TELEMEDICINE);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async checkTelemedicineRoomExistence(uuid) {
      await useApi("front")
        .get(`telemedicine-schedules/${uuid}/telemedicine-room`)
        .then(() => {
          this.telemedicineRoomExists = true;
        })
        .catch(() => {
          this.telemedicineRoomExists = false;
        });
    },
  },
  getters: {
    currentStepComponent(state) {
      let currentStepComponent = checkInSteps.find(
        (step) => step.type === state.currentStep
      )?.component;

      currentStepComponent =
        currentStepComponent ??
        virtualCheckInSteps.find((step) => step.type === state.currentStep)
          ?.component;

      return currentStepComponent;
    },
  },
});
