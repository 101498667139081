<template>
  <div v-if="loading" class="flex justify-center items-center h-full">
    <font-awesome-icon
      icon="fa-duotone fa-spinner-third"
      class="h-10 w-10"
      spin
    />
  </div>
  <div v-show="term && !loading" class="h-full">
    <div class="h-full flex flex-col">
      <h3 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
        {{ term?.title }}
      </h3>
      <p
        class="my-4 border-1 p-4 bg-white overflow-auto text-lg rounded-xl grow"
        v-html="term?.content"
      ></p>

      <div class="flex align-items-center my-4">
        <prime-checkbox
          inputId="accepted"
          name="accepted"
          v-model="accepted"
          :binary="true"
        />
        <label for="accepted" class="font-medium text-gray-700 pl-2">
          {{ capitalize(t("i_agree_with_the_terms")) }}
        </label>
      </div>

      <div class="mt-4">
        <prime-button
          :label="capitalize(t('continue'))"
          class="w-full !rounded-2xl"
          type="button"
          :disabled="!accepted"
          @click="acceptTerm"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useManagerApi } from "@/api";
import { isEmpty, notifyError } from "@/utils";
import { useToast } from "primevue/usetoast";
import { capitalize, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useCheckInStore } from "@/stores";
import { useRoute } from "vue-router";
import { i18n } from "@/lang";

const route = useRoute();
const patientScheduleUuid = route.params.uuid;

const { t } = useI18n();
const toast = useToast();

const checkinStore = useCheckInStore();

const accepted = ref(null);
const loading = ref(false);
const term = ref(null);

const fetchTerm = () => {
  loading.value = true;
  term.value = null;
  useManagerApi()
    .get(`/nick-telemedicine/check-in-steps/${patientScheduleUuid}/term/next`)
    .then(({ data }) => {
      accepted.value = false;

      term.value = data;
    })
    .catch(() => {
      notifyError(toast, capitalize(t("unexpected_error")), 5000);
    })
    .finally(() => {
      loading.value = false;
      if (isEmpty(term.value)) {
        finishStep();
      }
    });
};

const acceptTerm = async () => {
  loading.value = true;
  useManagerApi()
    .post(
      `/nick-telemedicine/check-in-steps/${patientScheduleUuid}/term/${term.value.id}/accept`,
      {
        device: navigator.userAgent,
      }
    )
    .then(fetchTerm)
    .catch(({ response }) => {
      if (response?.status === 422 && response?.data?.errors) {
        notifyError(toast, response.data?.message, 5000);
        return;
      }

      notifyError(
        toast,
        capitalize(i18n.global.t("internal_server_error")),
        5000
      );
    })
    .finally(() => {
      loading.value = false;
    });
};

const finishStep = async () => {
  loading.value = true;
  useManagerApi()
    .post(
      `/nick-telemedicine/check-in-steps/${patientScheduleUuid}/term/finish`
    )
    .then(() => {
      checkinStore.getNextStep(patientScheduleUuid);
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(fetchTerm);
</script>
