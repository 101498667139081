<template>
  <div class="text-sm text-gray-500">
    {{ capitalize(t("register_legal_guardian_description")) }}
  </div>
  <legal-guardian-form @created="patientLegalGuardianCreated" />
</template>

<script setup>
import { useI18n } from "vue-i18n";
import LegalGuardianForm from "@/components/legal-guardian-form.vue";
import { capitalize, onMounted } from "vue";
import { useCheckInStore } from "@/stores";
import { useManagerApi } from "@/api";
import { useRoute } from "vue-router";

const { t } = useI18n();

const checkInStore = useCheckInStore();
const route = useRoute();

const uuid = route.params.uuid;

const patientLegalGuardianCreated = (patientLegalGuardian) => {
  useManagerApi()
    .post(
      `/nick-telemedicine/check-in-steps/patient/${uuid}/select-legal-guardian/`,
      {
        legal_guardian_id: patientLegalGuardian.id,
        client_responsible_type_id:
          patientLegalGuardian.client_responsible_type_id,
      }
    )
    .then(() => {
      checkInStore.getNextStep(uuid);
    });
};

onMounted(() => {
  checkInStore.changeStepTitle("legal_guardian");
});
</script>
