<template>
  <div id="camera-container" class="relative h-full w-full">
    <video autoplay ref="video" id="video" :class="facingModeClass"></video>

    <div id="slot-container" class="absolute h-full w-full left-0 top-0">
      <slot></slot>
    </div>
  </div>
  <canvas ref="canvas" id="canvas" class="hidden"></canvas>
  <audio
    class="hidden"
    ref="audio"
    volume="0.5"
    src="/audio/camera-shutter-click.mp3"
  />
</template>

<script setup>
import {
  onMounted,
  onUnmounted,
  ref,
  computed,
} from "vue";

const emit = defineEmits([
  "loading",
  "started",
  "stopped",
  "paused",
  "resumed",
  "camera-change",
  "snapshot",
  "error",
]);

const props = defineProps({
  resolution: {
    type: Object,
    default: () => {
      return { width: 1920, height: 1080 };
    },
  },
  facingMode: {
    type: String,
    default: "environment",
  },
  autoplay: {
    type: Boolean,
    default: true,
  },
  playsinline: {
    type: Boolean,
    default: true,
  },
});

const video = ref(null);
const audio = ref(null);
const canvas = ref(null);
const stream = ref(null);

const constraints = {
  video: {
    width: props.resolution.width,
    height: props.resolution.height,
    facingMode: props.facingMode,
    deviceId: {},
  },
  audio: false,
};

const facingModeClass = computed(() => {
  return `${props.facingMode}-mode`;
});

const devices = async (kinds = ["audioinput", "videoinput"]) => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.filter((device) => kinds.includes(device.kind));
};

const currentDeviceID = () => {
  if (!stream.value) return;

  const tracks = stream.value
    .getVideoTracks()
    .map((track) => track.getSettings().deviceId);

  if (tracks.length > 0) return tracks[0];
};

const start = async () => {
  emit("loading");

  try {
    stream.value = await navigator.mediaDevices.getUserMedia(constraints);

    if (!video.value) throw new Error("Video ref is null");

    video.value.srcObject = stream.value;

    emit("started");
  } catch (err) {
    emit("error", err);
  }
};

const snapshot = (
  resolution = props.resolution,
  type = "image/png",
  quality = 1
) => {
  if (!video.value) throw new Error("Video ref is null");
  if (!canvas.value) throw new Error("Canvas ref is null");

  const videoWidth = video.value.clientWidth;
  const videoHeight = video.value.clientHeight;
  const { width, height } = resolution;

  if (videoHeight < videoWidth) {
    canvas.value.width = width;
    canvas.value.height = height;
    canvas.value.getContext("2d")?.drawImage(video.value, 0, 0, width, height);
  }

  if (videoHeight >= videoWidth) {
    canvas.value.width = height;
    canvas.value.height = width;
    canvas.value.getContext("2d")?.drawImage(video.value, 0, 0, height, width);
  }

  audio.value.play();

  return new Promise((resolve) => {
    canvas.value?.toBlob(
      (blob) => {
        emit("snapshot", blob);
        resolve(blob);
      },
      type,
      quality
    );
  });
};

const changeCamera = (deviceID) => {
  stop();
  constraints.video.deviceId.exact = deviceID;
  start();
  emit("camera-change", deviceID);
};

const resume = () => {
  video.value?.play();
  emit("resumed");
};

const pause = () => {
  video.value?.pause();
  emit("paused");
};

const stop = () => {
  stream.value?.getTracks().forEach((track) => track.stop());
  emit("stopped");
};

defineExpose({
  start,
  stop,
  video,
  snapshot,
  canvas,
  devices,
  currentDeviceID,
  pause,
  resume,
  changeCamera,
  stream,
});

onMounted(() => {
  if (!navigator.mediaDevices) throw new Error("Media devices not available");

  if (props.playsinline && video.value) {
    video.value.setAttribute("playsinline", "");
  }

  if (props.autoplay) start();
});

onUnmounted(() => stop());
</script>

<style scoped>
video {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

.user-mode {
  -ms-transform: translate(-50%, -50%) rotateY(180deg);
  transform: translate(-50%, -50%) rotateY(180deg);
}

.environment-mode {
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
