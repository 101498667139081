<template>
  <div class="py-2 h-[calc(100%-100px)]">
    <div class="flex flex-col h-full lg:w-1/2 justify-between">
      <div class="space-y-6">
        <p>
          {{ capitalize(t("sign_medical_autorization_guide_to_continue")) }}
        </p>

        <div class="bg-blue-100 rounded-xl p-4 text-blue-500 space-y-2">
          <b class="pb-4">
            <font-awesome-icon
              class="cursor-pointer mr-1"
              icon="far fa-exclamation-triangle"
            />
            {{ capitalize(t("attention")) }}
          </b>
          <p>
            {{ capitalize(t("if_under_18_guardian_must_sign")) }}
          </p>
        </div>

        <div class="flex items-center">
          <prime-checkbox
            name="accepted"
            input-id="accepted"
            v-model="accepted"
            class="mr-2"
            :binary="true"
          />
          <label for="accepted">
            {{ capitalize(t("agree_to_sign")) }}
          </label>
        </div>
      </div>

      <div class="pt-6">
        <prime-button
          :label="capitalize(t('continue'))"
          :disabled="!accepted"
          :loading="loading"
          class="w-full !rounded-full"
          @click="agreeToSignDocuments"
        />
      </div>
    </div>
  </div>

</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { capitalize, onMounted, ref } from "vue";
import { useCheckInStore } from "@/stores";
import { useManagerApi } from "@/api";
import { notifyError, notifySuccess } from "@/utils";
import { i18n } from "@/lang";
import { useToast } from "primevue/usetoast";
import { CAPTURE_FACE_FOR_DIGITAL_SIGNATURE } from "@/constants";

const { t } = useI18n();
const router = useRouter();
const toast = useToast();

const checkInStore = useCheckInStore();

const accepted = ref(false);
const loading = ref(false);

const uuid = router.currentRoute.value.params.uuid;

const agreeToSignDocuments = () => {
  loading.value = true;

  useManagerApi()
    .post(`/nick-telemedicine/check-in-steps/${uuid}/agree-to-sign-documents`)
    .then(() => {
      notifySuccess(
        toast,
        capitalize(i18n.global.t("successfully_agree_to_sign")),
        2000
      );

      checkInStore.changeCurrentStep(CAPTURE_FACE_FOR_DIGITAL_SIGNATURE);
    })
    .catch(() => {
      notifyError(
        toast,
        capitalize(i18n.global.t("internal_server_error")),
        5000
      );
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  checkInStore.changeStepTitle("document_signatures");
});
</script>
