export default [
	'2FA',
	'3D',
	'DateTime',
	'GitHub',
	'ID',
	'IDs',
	'iMac',
	'IMAX',
	'iOS',
	'IP',
	'iPad',
	'iPhone',
	'iPod',
	'macOS',
	'M2M',
	'M2O',
	'McDonalds',
	'MySQL',
	'O2M',
	'PDFs',
	'pH',
	'PostgreSQL',
	'YouTube',
	'UUID',
	'SEO',
	'CTA',
	'4K',
	'HD',
	'UHD',
	'5K',
	'8K',
];
